import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import KaleBrandsHelmet from "../components/utils/KaleBrandsHelmet";
import BrandReportDashboard from "../components/BrandReportDashboard";
import { Heading } from "../components/catalyst/heading";

export default function ReportsPage() {
  const { brandRecord } = useContext(UserContext);

  return (
    <>
      <KaleBrandsHelmet pageName="Reports" />
      <div className="flex flex-col justify-between space-y-6 sm:px-6 md:px-8">
        {/* Header */}
        <Heading>Reports</Heading>

        {brandRecord && <BrandReportDashboard brandId={brandRecord.id} />}
      </div>
    </>
  );
}
