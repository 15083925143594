import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ChallengesTable from "./ChallengesTable";
import { useHistory } from "react-router";
import ChallengeSuggestButton from "./ChallengeSuggestButton";
import { Heading } from "../catalyst/heading";

export default function BrandChallenges() {
  const { brandId } = useContext(UserContext);
  const history = useHistory();

  return (
    <div>
      <div className="sm:px-6 md:px-8 flex items-center justify-between">
        <Heading>Challenges</Heading>
        <div>
          <ChallengeSuggestButton
            onClick={() => {
              history.push("/challengeSuggestions?openModal=true");
            }}
          />
        </div>
      </div>
      <div className="sm:px-6 md:px-8">
        <ChallengesTable key={brandId} brandId={brandId} />
      </div>
    </div>
  );
}
