import { useMutation } from "@apollo/client";
import { UPDATE_BRAND_PO_NUMBERS } from "../../graphql/mutations";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "../catalyst/fieldset";
import { Input } from "../catalyst/input";
import { Checkbox, CheckboxField } from "../catalyst/checkbox";
import { Divider } from "../catalyst/divider";
import { Button } from "../catalyst/button";
import { Heading, Subheading } from "../catalyst/heading";
import { Brand } from "@kalecard/common";

export interface PONumberFormProps {
  brand?: Brand;
  postSubmit: (brandId: string) => Promise<void>;
}

export function PONumberForm(props: PONumberFormProps) {
  const [updateBrandPONumbers] = useMutation(UPDATE_BRAND_PO_NUMBERS);
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm({ defaultValues: propsToDefaultFormValues(props) });
  const platformFeePoNumber = props.brand?.platformFeePoNumber;
  const contentBudgetPoNumber = props.brand?.contentBudgetPoNumber;

  const onSubmit: SubmitHandler<PONumberFields> = async (data) => {
    let platformFeePoNumber = data.platformFeePoNumber;
    let contentBudgetPoNumber = data.contentBudgetPoNumber;
    if (!advancedSettings) {
      platformFeePoNumber = data.poNumber;
      contentBudgetPoNumber = data.poNumber;
    }

    await updateBrandPONumbers({
      variables: {
        brandId: props.brand?.id,
        platformFeePoNumber: platformFeePoNumber,
        contentBudgetPoNumber: contentBudgetPoNumber,
      },
    });

    await props.postSubmit(props.brand?.id);
  };

  useEffect(() => {
    if (advancedSettings) {
      setValue("poNumber", "");
    }
  }, [advancedSettings, getValues, setValue]);

  useEffect(() => {
    reset(propsToDefaultFormValues(props));
    setAdvancedSettings(contentBudgetPoNumber !== platformFeePoNumber);
    console.log(contentBudgetPoNumber, platformFeePoNumber);
  }, [props, reset]);

  return (
    <div className="mx-auto flex text-sm sm:px-6 md:px-8">
      <div className="flex flex-col gap-y-6">
        <Subheading>Invoice Settings</Subheading>
        <Fieldset>
          <FieldGroup>
            <Field disabled={advancedSettings}>
              <Label>PO number</Label>
              <Input
                {...register("poNumber", {
                  validate: (value) => {
                    if (advancedSettings) {
                      return true;
                    }

                    return !(!value || value.length < 1 || value.length > 140);
                  },
                })}
                placeholder={advancedSettings ? "" : "PO 24052021-000001"}
              />
              <Description>
                This PO Number will be appended to all Kale invoices.
              </Description>
            </Field>

            <CheckboxField>
              <Label>Advanced settings</Label>
              <Checkbox
                onChange={(toggled) => setAdvancedSettings(toggled)}
                checked={advancedSettings}
                color="kale"
              />
              <Description>
                Enable to specify a PO number for each type of invoice
              </Description>
            </CheckboxField>
            {advancedSettings && (
              <>
                <Field>
                  <Label>Platform fee PO number</Label>
                  <Input
                    {...register("platformFeePoNumber", {
                      required: true,
                      minLength: 1,
                      maxLength: 140,
                    })}
                    placeholder="PO 24052021-PF-000001"
                  />
                </Field>
                <Field>
                  <Label>Content budget PO number</Label>
                  <Input
                    {...register("contentBudgetPoNumber", {
                      required: true,
                      minLength: 1,
                      maxLength: 140,
                    })}
                    placeholder="PO 24052021-CB-000001"
                  />
                </Field>
              </>
            )}
          </FieldGroup>
        </Fieldset>
        <Divider />
        <div className="flex justify-end">
          <Button
            type="submit"
            color="kaleGreen"
            disabled={!isValid || !isDirty || isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

function propsToDefaultFormValues(props: PONumberFormProps): PONumberFields {
  const platformFeePoNumber = props.brand?.platformFeePoNumber;
  const contentBudgetPoNumber = props.brand?.contentBudgetPoNumber;
  return {
    poNumber:
      contentBudgetPoNumber === platformFeePoNumber
        ? contentBudgetPoNumber
        : "",
    platformFeePoNumber: platformFeePoNumber,
    contentBudgetPoNumber: contentBudgetPoNumber,
  };
}

interface PONumberFields {
  poNumber: string;
  platformFeePoNumber: string;
  contentBudgetPoNumber: string;
}
