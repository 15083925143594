import { useContext, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../catalyst/dialog";
import { DialogPanel } from "@headlessui/react";
import { Textarea } from "../catalyst/textarea";
import { Button } from "../catalyst/button";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_BRAND_INFLUENCER_POSTS } from "../../graphql/mutations";
import { BRAND_INFLUENCER_POSTS } from "../../graphql/queries";
import { InstagramLogo, Spinner, TiktokLogo } from "@kalecard/common";
import { Link } from "../catalyst/link";
import { Badge } from "../catalyst/badge";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "../Tooltip";
import { Subheading } from "../catalyst/heading";
import { Text } from "../catalyst/text";

export default function InfluencerPosts() {
  const { brandId } = useContext(UserContext);
  const [addMoreDialogOpen, setAddMoreDialogOpen] = useState(false);
  const { data, loading, refetch } = useQuery(BRAND_INFLUENCER_POSTS, {
    variables: {
      brandId,
    },
  });

  return (
    <div>
      <div className="flex flex-row items-center justify-between pb-4">
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-row gap-x-2">
            <Subheading>
              Influencer Posts
            </Subheading>
            <div
              className="flex flex-col justify-end hover:cursor-pointer hover:text-kale-green-500"
              onClick={() => refetch()}
            >
              <div className="flex items-center space-x-2">
                <Badge>
                  <ArrowPathIcon className="h-4 w-4" />
                </Badge>
              </div>
            </div>
          </div>
          <Text>
            Add posts from influencers that you have worked with
          </Text>
        </div>
        <div>
          <Button color="emerald" onClick={() => setAddMoreDialogOpen(true)}>
            Add Posts
          </Button>
          <AddMoreDialog
            open={addMoreDialogOpen}
            onClose={() => setAddMoreDialogOpen(false)}
            brandId={brandId}
          />
        </div>
      </div>
      <div></div>
      <div className="mx-4 mt-4">
        {loading ? (
          <div className="flex justify-center">
            <Spinner size={"h-6 w-6"} />
          </div>
        ) : (
          <div className="mx-4 flex flex-col gap-y-4">
            {data?.brandInfluencerPosts?.edges?.map((edge) => (
              <BrandInfluencerPostRowItem
                id={edge.node.id}
                postUrl={edge.node.postUrl}
                state={edge.node.state}
                platform={
                  edge.node?.post?.externalPlatform ??
                  GetPlatformFromUrl(edge.node.postUrl)
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function AddMoreDialog({
  open,
  onClose,
  brandId,
}: {
  open: boolean;
  onClose: () => void;
  brandId: string;
}) {
  const [addBrandInfluencerPosts] = useMutation(ADD_BRAND_INFLUENCER_POSTS);
  const [rawPostUrls, setRawPostUrls] = useState("");
  const [error, setError] = useState("");

  const submitPosts = () => {
    const urls = rawPostUrls
      .split(/[\n,]/)
      .filter((str) => str != "")
      .map((url) => url.trim());
    if (urls.length === 0) {
      setError("Please enter at least one URL");
      return;
    }
    addBrandInfluencerPosts({
      variables: {
        brandId,
        postUrls: urls,
      },
      refetchQueries: [BRAND_INFLUENCER_POSTS],
    })
      .then(() => {
        setRawPostUrls("");
        onClose();
      })
      .catch((e) => {
        setError(e.message);
      });
  };
  return (
    <Dialog open={open} onClose={onClose} size="lg">
      <DialogTitle>Add Brand Influencer Posts</DialogTitle>
      <DialogBody>
        <DialogPanel>
          <Textarea
            value={rawPostUrls}
            onChange={(e) => {
              setRawPostUrls(e.target.value);
              setError("");
            }}
            placeholder="Enter influencer post URLs here, separated by commas or one per line."
          />
          {error.length > 0 ? <p className="text-red-500">{error}</p> : null}
        </DialogPanel>
      </DialogBody>
      <DialogActions>
        <Button color="green" onClick={submitPosts}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const BrandInfluencerPostRowItem = ({
  id,
  postUrl,
  state,
  platform,
}: {
  id: string;
  postUrl: string;
  state: string;
  platform?: string;
}) => (
  <div
    key={id}
    className="flex flex-row items-center justify-between rounded-md border p-2 shadow"
  >
    <div className="flex flex-row items-center gap-x-2">
      {platform != null ? (
        platform === "INSTAGRAM" ? (
          <InstagramLogo />
        ) : (
          <TiktokLogo />
        )
      ) : null}
      <Link
        className="text-kale-green-500"
        href={postUrl}
        target="_blank"
        rel="noreferrer"
      >
        {postUrl}
      </Link>
    </div>
    {GetStatePill(state)}
  </div>
);

const GetPlatformFromUrl = (url: string) => {
  if (url.includes("instagram")) {
    return "INSTAGRAM";
  } else if (url.includes("tiktok")) {
    return "TIKTOK";
  } else {
    return null;
  }
};

const GetStatePill = (state: string) => {
  return (
    <Tooltip
      message={
        state === "PENDING"
          ? "We are actively indexing this post. When it's ready, we will flag creators who try to copy from it."
          : state === "ERROR"
          ? "Something went wrong when trying to index this post."
          : "We have successfully indexed this post. We will flag creators who try to copy from it."
      }
    >
      <Badge
        color={
          state === "PENDING" ? "amber" : state === "ERROR" ? "red" : "green"
        }
      >
        {state}
      </Badge>
    </Tooltip>
  );
};
