import { ApolloQueryResult, OperationVariables, useMutation, useQuery } from "@apollo/client";
import { Heading, Subheading } from "../../catalyst/heading";
import { Text } from "../../catalyst/text";
import { PENDING_CHALLENGE_REQUESTS, PendingChallengeRequestsData } from "../../../graphql/queries";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { Brand, Spinner } from "@kalecard/common";
import EmptyDataState from "../../EmptyDataState";
import { ChallengeRequest } from "../../../__generated__/graphql";
import { TikTokEmbed } from "react-social-media-embed";
import { Button } from "../../catalyst/button";
import { Link } from "../../catalyst/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCompactDisc } from "@fortawesome/free-solid-svg-icons";
import { REVIEW_CHALLENGE_REQUEST } from "../../../graphql/mutations";
import { Dialog } from "../../catalyst/dialog";
import { BadgesContext } from "../../../providers/BadgesProvider";

export default function ChallengeTrendRequests() {
  const { brandId, brandRecord } = useContext(UserContext);
  const { refreshBadges } = useContext(BadgesContext);
  const { data, loading, refetch } =
    useQuery<PendingChallengeRequestsData>(PENDING_CHALLENGE_REQUESTS, {
      variables: {
        brandId: brandId,
        source: "KALE_ADMIN",
        templateType: "TREND"
      },
      skip: !brandId,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: () => console.log("loaded")
    });

  const onReview = () => {
    setTimeout(() => {
      refetch();
      refreshBadges();
    }, 500);
  }

  return (
    <div className="sm:px-6 md:px-8 space-y-6">
      {/* Header */}
      <div>
        <Heading>Trends</Heading>
        <Text>Once you click "Launch" for a trend, the Kale team will create a challenge tailored to your brand and automatically launch it to your creators.</Text>
      </div>

      {/* Data */}
      {(loading || !brandRecord || !data) ? (
        <div className="flex justify-center">
          <Spinner size="h-8 w-8" />
        </div>
      ) : (
        data?.pendingChallengeRequests?.length > 0 ? (
          <Trends challengeRequests={data.pendingChallengeRequests} brand={brandRecord} onReview={onReview}/>
        ) : (
          <EmptyDataState message="There are no trends waiting to be approved." />
        )
      )}
    </div>
  );
}

function Trends({ challengeRequests, brand, onReview } : { challengeRequests: ChallengeRequest[], brand: Brand, onReview: () => void }) {
  return (
    <div className="flex flex-wrap gap-4">
      {challengeRequests.map((challengeRequest) => (
        <TrendComponent key={challengeRequest.id} challengeRequest={challengeRequest} brand={brand} onReview={onReview} />
      ))}
  </div>
  );
}

function TrendComponent({ challengeRequest, brand, onReview } : { challengeRequest: ChallengeRequest, brand: Brand, onReview: () => void; }) {
  const [showLaunchTrendDialog, setShowLaunchTrendDialog] = useState(false);
  const [reviewChallengeRequest] = useMutation(REVIEW_CHALLENGE_REQUEST);
  const [dismissLoading, setDismissLoading] = useState(false);

  const onDismiss = async () => {
    setDismissLoading(true);
    try {
      const variables = {
        brandId: brand.id,
        challengeRequestId: challengeRequest.id,
        isApproved: false,
      };

      await reviewChallengeRequest({
        variables: variables
      });
      onReview();
    } catch (err) {
      console.error(err);
    }
    setDismissLoading(false);
  }

  const trend = challengeRequest.challengeTemplate;
  return (
    <div className="border rounded-md p-4 space-y-4">
      <div className="grid grid-cols-5">
        <Subheading className="col-start-2 col-span-3 text-center">{trend.title}</Subheading>
        <div className="col-start-5 col-span-1 text-end">
          {trend.soundUrl && (<Link href={trend.soundUrl} target="_blank">
              <FontAwesomeIcon className="ml-auto" icon={faCompactDisc as IconProp} size="xl" />
            </Link>
          )}
        </div>
      </div>
      <div className="grid grid-cols-5 items-center">
        <div className="col-start-2 col-span-3 space-x-4 text-center">
          <Button className="hover:cursor-pointer" color="emerald" onClick={() => setShowLaunchTrendDialog(true) }>
            Launch
          </Button>
          <Button className="hover:cursor-pointer" color="red" onClick={onDismiss}>
            Dismiss
          </Button>
        </div>
        {dismissLoading && (<Spinner size={"h-5 w-5"} />)}
      </div>
      <TikTokEmbed url={trend.exampleUrl} width={325} />
      <Dialog onClose={setShowLaunchTrendDialog} open={showLaunchTrendDialog}>
        <LaunchTrend challengeRequest={challengeRequest} brand={brand} onSubmit={() => {
          setShowLaunchTrendDialog(false);
          onReview();
        }}/>
      </Dialog>
    </div>
  );
}

function LaunchTrend({ challengeRequest, brand, onSubmit } : { challengeRequest: ChallengeRequest, brand: Brand, onSubmit: () => void }) {
  const trend = challengeRequest.challengeTemplate;
  const shouldShowCopyrightNotice = (trend?.soundUrl != null) && (brand?.shouldRequireCommercialSound === true);
  console.log(shouldShowCopyrightNotice);
  console.log(trend);
  console.log(brand);

  const [reviewChallengeRequest] = useMutation(REVIEW_CHALLENGE_REQUEST, { refetchQueries: [PENDING_CHALLENGE_REQUESTS] });
  const [loading, setLoading] = useState(false);

  const onLaunch = async () => {
    setLoading(true);
    try {
      const variables = {
        brandId: brand.id,
        challengeRequestId: challengeRequest.id,
        isApproved: true,
      };

      await reviewChallengeRequest({
        variables: variables
      });
      onSubmit();
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }

  return (
    <div className="space-y-4 text-center">
      <Heading>Heads Up!</Heading>
      {shouldShowCopyrightNotice && (
        <div>
          <Text>Your preferences are set to require commercial-approved sounds only on every video. By launching this trend-based challenge, you acknowledge that the sound will not be commercial approved.</Text>
        </div>
      )}
      <Text>The Kale team will launch this challenge within 2 business days.</Text>
      <div className="grid grid-cols-5 items-center">
        <div className="col-start-2 col-span-3 space-x-4 text-center">
          <Button className="hover:cursor-pointer" outline onClick={onSubmit}>
            Go back
          </Button>
          <Button className="hover:cursor-pointer" color="indigo" onClick={onLaunch}>
            I acknowledge
          </Button>
        </div>
        {loading && (<Spinner size={"h-5 w-5"} />)}
      </div>
    </div>
  );
}