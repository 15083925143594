import { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import Sidebar from "../components/navbar/Sidebar";
import { UserContext } from "../providers/UserProvider";
import { localStorageService, LocalStorageKey, Modal } from "@kalecard/common";
import OnboardingSkeleton from "./OnboardingSkeleton";
import SignUp from "./SignUp";
import ContentPage from "./ContentPage";
import WhitelistContentPage from "./WhitelistContentPage";
import OrdersPage from "./OrdersPage";
import BillingPage from "./BillingPage";
import ReportsPage from "./ReportsPage";
import SignoutPage from "./SignoutPage";
import CreatorsPage from "./CreatorsPage";
import SettingsPage from "./SettingsPage";
import InstagramMessagesPage from "./InstagramMessagesPage";
import InstagramMediaPage from "./InstagramMediaPage";
import InstagramTaggedMediaPage from "./InstagramTaggedMediaPage";
import ResetPasswordPage from "./ResetPasswordPage";
import { useMutation } from "@apollo/client";
import { AUTHENTICATE_BRAND_EMAIL } from "../graphql/mutations";
import firebase from "firebase";
import { auth } from "../config/firebase";
import TermsPage from "../components/BrandTerms";
import BadgesProvider from "../providers/BadgesProvider";
import ChallengesPage from "./ChallengesPage";
import LoadingPage from "./LoadingPage";
import ChallengesPausedBanner from "../components/ChallengesPausedBanner";
import { classNames } from "../utils/style";
import CompleteProfileDataForm from "../components/forms/CompleteProfileDataForm";
import FacebookPage from "./FacebookPage";
import PostCollectionPage from "./PostCollectionPage";
import VideoReviewPage from "./VideoReviewPage";
import BrandSetup from "../components/BrandSetup";
import { Dialog } from "../components/catalyst/dialog";
import PendingChallenges from "../components/challenges/PendingChallenges";
import ChallengeTrendRequests from "../components/challenges/requests/ChallengeTrendRequests";

export default function Application() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorageService.getBoolean(LocalStorageKey.AUTHENTICATED)
  );
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const { user, userRecord, brandRecord } = useContext(UserContext);
  const [authenticateBrandEmail] = useMutation(AUTHENTICATE_BRAND_EMAIL);
  const [shouldShowPausedBanner, setShouldShowPausedBanner] = useState(false);
  const [shouldShowCompleteProfileData, setShouldShowCompleteProfileData] =
    useState(false);
  const [shouldShowSetupForm, setShouldShowSetupForm] = useState(
    brandRecord?.isBrandSideSetupComplete == false
  );

  const checkBrandEmail = async (user: firebase.User) => {
    const result = await authenticateBrandEmail({
      variables: {
        brandEmail: user.email,
      },
    });
    if (!result.data.authenticateBrandEmail) {
      auth.signOut();
    } else {
      setIsEmailValid(true);
    }
  };

  useEffect(() => {
    setIsAuthenticated(
      localStorageService.getBoolean(LocalStorageKey.AUTHENTICATED)
    );

    if (user) {
      checkBrandEmail(user);
    }

    setShouldShowPausedBanner(brandRecord?.challengesPaused);
    setShouldShowSetupForm(brandRecord?.isBrandSideSetupComplete == false);
    setShouldShowCompleteProfileData(
      userRecord !== null &&
        userRecord?.companyTitle === null &&
        userRecord?.role !== "KALE_ADMIN" &&
        brandRecord?.isBrandSideSetupComplete == false
    );

    return () => {};
  }, [user, userRecord, brandRecord]);

  const loggedInSwitch = (
    <>
      {user && isEmailValid ? (
        <div className="h-full">
          {shouldShowPausedBanner && (
            <ChallengesPausedBanner
              onBannerClose={() => setShouldShowPausedBanner(false)}
            />
          )}

          <div
            className={classNames(
              "flex h-full flex-col bg-white md:flex-row",
              shouldShowPausedBanner ? "pt-12" : ""
            )}
          >
            <BadgesProvider>
              <>
                <div className="flex items-center md:items-start">
                  <Sidebar shouldShowPausedBanner={shouldShowPausedBanner} />
                </div>
                <main className="flex-1">
                  <div className="h-full md:pt-6">
                    <Switch>
                      <Route path="/profile">
                        <SettingsPage />
                      </Route>
                      <Route path="/content/">
                        <ContentPage />
                      </Route>
                      <Route path="/collection/:id">
                        <PostCollectionPage />
                      </Route>
                      <Route path="/billing">
                        <BillingPage />
                      </Route>
                      <Route path="/creators">
                        <CreatorsPage />
                      </Route>
                      <Route path="/whitelisting/">
                        <WhitelistContentPage />
                      </Route>
                      <Route path="/reports">
                        <ReportsPage />
                      </Route>
                      <Route path="/orders">
                        <OrdersPage />
                      </Route>
                      <Route path="/signout">
                        <SignoutPage />
                      </Route>
                      <Route path="/settings">
                        <SettingsPage />
                      </Route>
                      <Route path="/challenges">
                        <ChallengesPage />
                      </Route>
                      <Route path="/pendingChallenges">
                        <ChallengesPage />
                      </Route>
                      <Route path="/challengeSuggestions">
                        <ChallengesPage />
                      </Route>
                      <Route path="/facebook">
                        <FacebookPage />
                      </Route>
                      <Route path="/instagram/:id/media">
                        <InstagramMediaPage />
                      </Route>
                      <Route path="/instagram/:id/taggedMedia">
                        <InstagramTaggedMediaPage />
                      </Route>
                      <Route path="/instagram/:id/messages/:mid">
                        <InstagramMessagesPage />
                      </Route>
                      <Route path="/instagram/:id/messages">
                        <InstagramMessagesPage />
                      </Route>
                      <Route path="/terms">
                        <OnboardingSkeleton>
                          <TermsPage />
                        </OnboardingSkeleton>
                      </Route>
                      <Route path="/videoReview">
                        <VideoReviewPage />
                      </Route>
                      <Route path="/trends">
                        <ChallengeTrendRequests />
                      </Route>
                      <Route path="/">
                        <ContentPage />
                      </Route>
                    </Switch>
                  </div>
                </main>
              </>
            </BadgesProvider>
          </div>
          <Modal
            isModalOpen={shouldShowCompleteProfileData}
            showCloseIcon={false}
            disableOutsideClick={true}
            className="w-fit"
          >
            <CompleteProfileDataForm
              onSubmit={() => setShouldShowCompleteProfileData(false)}
            />
          </Modal>
          <Dialog
            open={shouldShowSetupForm}
            onClose={() =>
              userRecord.role === "KALE_ADMIN"
                ? setShouldShowSetupForm(false)
                : null
            }
            size={"5xl"}
          >
            <BrandSetup />
          </Dialog>
        </div>
      ) : (
        <LoadingPage />
      )}
    </>
  );

  const loggedOutSwitch = (
    <Switch>
      <Route path="/resetPassword">
        <OnboardingSkeleton>
          <ResetPasswordPage authenticated={isAuthenticated} />
        </OnboardingSkeleton>
      </Route>
      <Route path="/login">
        <OnboardingSkeleton>
          <SignUp isSignIn={true} authenticated={isAuthenticated} />
        </OnboardingSkeleton>
      </Route>
      <Route path="/signup">
        <OnboardingSkeleton>
          <SignUp isSignIn={false} authenticated={isAuthenticated} />
        </OnboardingSkeleton>
      </Route>
      <Route path="/terms">
        <OnboardingSkeleton>
          <TermsPage />
        </OnboardingSkeleton>
      </Route>
      <Route path="/">
        <OnboardingSkeleton>
          <SignUp isSignIn={false} authenticated={isAuthenticated} />
        </OnboardingSkeleton>
      </Route>
    </Switch>
  );

  return (
    <div className="h-full">
      {isAuthenticated ? loggedInSwitch : loggedOutSwitch}
    </div>
  );
}
