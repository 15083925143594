import { Switch, Route } from "react-router";
import { NavLink } from "react-router-dom";
import MyPlanPage from "../components/billing/MyPlanPage";
import PaymentHistoryPage from "../components/billing/PaymentHistoryPage";
import { FundsPage } from "../components/settings/payments/FundsPage";
import { Button } from "../components/catalyst/button";
import { ArrowUpRightIcon, CreditCardIcon } from "@heroicons/react/16/solid";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { useLazyQuery } from "@apollo/client";
import { INITIATE_CUSTOMER_PORTAL } from "../graphql/queries";
import { PONumberForm } from "../components/billing/BillingSettingsForm";
import { Heading } from "../components/catalyst/heading";

const tabs = [
  { name: "My Plan", href: `/billing/plan`, current: true },
  { name: "Add Funds", href: `/billing/funds`, current: false },
  { name: "Payment History", href: `/billing/history`, current: false },
  {
    name: "Invoice Settings",
    href: "/billing/invoice-settings",
    current: false,
  },
];

export default function BillingPage() {
  const { brandRecord, updateBrandRecord } = useContext(UserContext);
  const [initiateCustomerPortal] = useLazyQuery(INITIATE_CUSTOMER_PORTAL);

  const handleCustomerPortalClick = async () => {
    const response = await initiateCustomerPortal({
      variables: { brandId: brandRecord.id },
      fetchPolicy: "no-cache",
    });
    const url = response?.data?.initiateCustomerPortal?.url;

    window.open(url, "_blank");
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between sm:px-6 md:px-8">
        <Heading>Billing</Heading>
        <Button
          href={"#"}
          onClick={() => handleCustomerPortalClick()}
          color={"white"}
        >
          <CreditCardIcon />
          Update billing information
          <ArrowUpRightIcon />
        </Button>
      </div>
      <div className="border-b border-gray-200 sm:px-6 md:px-8">
        <div className="block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={(isActive) =>
                  (isActive
                    ? "border-b-2 border-black text-black "
                    : "text-gray-500 hover:border-b-2 hover:border-gray-500 hover:text-gray-600") +
                  "whitespace-nowrap px-1 pb-4 text-sm font-medium"
                }
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div>
        <Switch>
          <Route path="/billing/history">
            <PaymentHistoryPage />
          </Route>
          <Route exact path="/billing/funds">
            <FundsPage />
          </Route>
          <Route exact path="/billing/plan">
            <MyPlanPage />
          </Route>
          <Route path="/billing/invoice-settings">
            <PONumberForm brand={brandRecord} postSubmit={updateBrandRecord} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
