import { useContext, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import { CHALLENGE_SUGGESTIONS } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { Brand, Modal } from "@kalecard/common";
import SuggestChallengeForm from "../forms/SuggestChallengeForm";
import { useLocation } from "react-router-dom";
import ChallengeSuggestButton from "./ChallengeSuggestButton";
import UpdateSuggestedChallengeForm from "../forms/UpdateSuggestedChallengeForm";
import { Challenge } from "../../__generated__/graphql";
import {
  ArrowTopRightOnSquareIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import EmptyDataState from "../EmptyDataState";
import { Badge } from "../catalyst/badge";
import { Heading } from "../catalyst/heading";

export default function ChallengeSuggestions() {
  const { brandId, brandRecord } = useContext(UserContext);
  const { data } = useQuery(CHALLENGE_SUGGESTIONS, {
    variables: {
      brandId: brandId,
    },
    skip: !brandId,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const openModal = queryParams.get("openModal");

  const [isSuggestChallengeModalOpen, setIsSuggestChallengeModalOpen] =
    useState(openModal === "true");

  const handleModalSubmit = () => {
    setIsSuggestChallengeModalOpen(false);
  };

  return (
    <div className="space-y-6">
      <div className="sm:px-6 md:px-8 flex items-center justify-between">
        <Heading>Challenge Suggestions</Heading>
        <div>
          <ChallengeSuggestButton
            onClick={() => {
              setIsSuggestChallengeModalOpen(true);
            }}
          />
        </div>
      </div>
      <div className="sm:px-6 md:px-8">
        <SuggestionsTable
          brand={brandRecord}
          suggestions={
            data?.challengeSuggestions.map((c) => c as Challenge) ?? []
          }
        />
      </div>
      <Modal
        isModalOpen={isSuggestChallengeModalOpen}
        onClose={() => {
          setIsSuggestChallengeModalOpen(false);
        }}
      >
        <div className="item-start flex w-full">
          <SuggestChallengeForm
            brand={brandRecord}
            onSubmit={handleModalSubmit}
          />
        </div>
      </Modal>
    </div>
  );
}

const SuggestionsTable = ({
  brand,
  suggestions,
}: {
  brand: Brand;
  suggestions: Challenge[];
}) => (
  <div className="flex-cols flex w-full">
    <div className="w-full">
      <div className="inline-block w-full py-2 align-middle">
        <div className="overflow-hidden">
          {suggestions.length > 0 ? (
            <table className="w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-2 py-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Theme
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-4 text-center text-sm font-semibold text-gray-900"
                  >
                    Suggested At
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-4 text-center text-sm font-semibold text-gray-900"
                  >
                    Start Date
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-4 text-center text-sm font-semibold text-gray-900"
                  >
                    End Date
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-4 text-center text-sm font-semibold text-gray-900"
                  >
                    Your Comments
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-4 text-center text-sm font-semibold text-gray-900"
                  >
                    {/* Edit Button Section */}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {suggestions.map((challenge) => (
                  <SuggestionRow brand={brand} challenge={challenge} />
                ))}
              </tbody>
            </table>
          ) : (
            <EmptyDataState message="No challenge suggestions pending Kale's approval." />
          )}
        </div>
      </div>
    </div>
  </div>
);

const SuggestionRow = ({
  brand,
  challenge,
}: {
  brand: Brand;
  challenge: Challenge;
}) => {
  const [isEditSuggestionModalOpen, setIsEditSuggestionModalOpen] =
    useState(false);
  const handleEditSuggestionSubmit = () => {
    setIsEditSuggestionModalOpen(false);
  };

  const theme = challenge.challengeTemplate?.title ?? challenge.category?.name;
  return (
    <tr>
      {/* Theme */}
      <td className="px-2 py-4 text-sm text-gray-500">
        {theme && (
          <div className="text-left text-sm text-gray-900">
            {theme}
          </div>
        )}
        {challenge.exampleUrl && (
          <a
            className="flex items-center space-x-1 pt-2 text-kale-green-500"
            href={challenge.exampleUrl}
            target="_blank"
            rel="noreferrer"
          >
            <p>See Example</p>
            <ArrowTopRightOnSquareIcon className="h-4 w-4" />
          </a>
        )}
      </td>
      {/* Suggested At */}
      <td className="px-2 text-center text-sm text-gray-900">
        {challenge.createdAt
          ? new Date(Number(challenge.createdAt)).toLocaleDateString()
          : null}
      </td>
      {/* Start Date */}
      <td className="space-y-2 px-2 text-center text-sm text-gray-900">
        {challenge.startDate || challenge.firstActivationDate
          ? new Date(
              challenge.startDate
                ? Number(challenge.startDate)
                : Number(challenge.firstActivationDate)
            ).toLocaleDateString()
          : challenge.challengeTemplate?.templateType === "TREND" ? <p>In 2 business days</p> : null}

        {challenge.challengeTemplate?.templateType === "TREND" && (
          <Badge className="w-fit">Trend</Badge>
        )}
      </td>
      {/* End Date */}
      <td className="px-2 text-center text-sm text-gray-900">
        {challenge.endDate
          ? new Date(Number(challenge.endDate)).toLocaleDateString()
          : null}
      </td>
      {/* Comments */}
      <td className="max-w-md px-2 py-2 text-center text-sm text-gray-900">
        {challenge.feedback && challenge.feedback[0] ? (
          <p>{challenge.feedback[0].feedback}</p>
        ) : null}
      </td>
      {/* Edit Button */}
      <td>
        {challenge.challengeTemplate?.templateType !== "TREND" && (<button
          onClick={() => setIsEditSuggestionModalOpen(true)}
          className="ml-2 inline-flex flex-col justify-center py-2 text-kale-green-500 hover:text-kale-green-400 focus:outline-none"
        >
          <PencilSquareIcon
            className="h-6 w-6 flex-shrink-0"
            aria-hidden="true"
          />
        </button>)}
        <Modal
          isModalOpen={isEditSuggestionModalOpen}
          onClose={() => setIsEditSuggestionModalOpen(false)}
        >
          <div className="flex w-full">
            <UpdateSuggestedChallengeForm
              brand={brand}
              challenge={challenge}
              onSubmit={handleEditSuggestionSubmit}
              onClose={() => setIsEditSuggestionModalOpen(false)}
            />
          </div>
        </Modal>
      </td>
    </tr>
  );
};
