import { Spinner, BrandContentData } from "@kalecard/common";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import KaleBrandsHelmet from "../components/utils/KaleBrandsHelmet";
import ContentProvider from "../providers/ContentProvider";
import { contentSortingTypes } from "../reducers/ContentReducer";
import { useQuery } from "@apollo/client";
import {
  CONTENT,
  ChallengesListInterface,
  SIMPLE_CHALLENGES_LIST,
} from "../graphql/queries";
import BrandsContent from "../components/content/BrandsContent";
import { Heading } from "../components/catalyst/heading";

export default function ContentPage() {
  const { brandId } = useContext(UserContext);

  const { loading, data } = useQuery<BrandContentData>(CONTENT, {
    variables: {
      brandId: brandId,
      after: "0",
      kalePicks: true,
      challengeIds: null,
    },
  });

  const { loading: challengesLoading, data: challengesData } =
    useQuery<ChallengesListInterface>(SIMPLE_CHALLENGES_LIST, {
      variables: {
        brandId: brandId,
        onlyCompleted: true,
        onlyActive: true,
      },
    });

  return (
    <>
      <KaleBrandsHelmet pageName="Content" />
      <Heading className="sm:px-6 md:px-8">Content</Heading>
      {!loading && !challengesLoading ? (
        <ContentProvider
          contentState={{
            content: data?.content?.edges?.map((edge) => edge.node),
            hasNextPage: data?.content?.pageInfo?.hasNextPage,
            after: data?.content?.edges?.length?.toString(),
            challengeId: null,
            sortingType: contentSortingTypes.find(
              (sortingType) => sortingType.id === "KALE_PICKS"
            ),
            shouldUpdateContent: false,
            selectableChallenges: challengesData?.challengesList?.edges?.map(
              (challenge) => challenge.node
            ),
          }}
        >
          <BrandsContent />
        </ContentProvider>
      ) : (
        <div className="flex justify-center pt-10">
          <Spinner size={"h-8 w-8"} />
        </div>
      )}
    </>
  );
}
