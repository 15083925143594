import { useQuery } from "@apollo/client";
import { PENDING_CHALLENGES, PendingChallengesInterface } from "../../graphql/queries";
import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import PendingChallengesTable from "./PendingChallengesTable";
import { Spinner } from "@kalecard/common";
import { Heading } from "../catalyst/heading";
import { Text } from "../catalyst/text";

export default function PendingApprovalChallenges() {
  const { brandId } = useContext(UserContext);

  const { data: pendingChallengesData, loading: pendingChallengesLoading } =
    useQuery<PendingChallengesInterface>(PENDING_CHALLENGES, {
      variables: {
        brandId: brandId,
      },
      skip: !brandId,
    });
    
  return (
    pendingChallengesLoading ? (
      <div>
        <Spinner size="h-5 w-5" />
      </div>
    ) : (
      <div className="space-y-6">
        <div>
        <Heading>Pending Your Approval</Heading>
        <Text>These challenges will activate as soon as you approve them (or on the scheduled activation date).</Text>
        </div>
        <PendingChallengesTable key={brandId} pendingChallenges={pendingChallengesData?.pendingChallenges} />
      </div>
    )
  );
}