import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import { numberWithCommas, prettyDecimal, Spinner } from "@kalecard/common";
import { useQuery } from "@apollo/client";
import {
  BrandReferralCodeCount,
  BrandCreatorsData,
} from "../../__generated__/graphql";
import { BRAND_CREATORS_DATA } from "../../graphql/queries";
import { Subheading } from "../catalyst/heading";

export default function CreatorStats() {
  const { brandId } = useContext(UserContext);
  const { data, loading } = useQuery(BRAND_CREATORS_DATA, {
    variables: {
      brandId: brandId,
    },
    fetchPolicy: "network-only",
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <CreatorsTable creatorsData={data?.creatorsData} />
          </div>
          {data?.creatorsData?.creators > 0 && (
            <div>
              <CodeBreakoutTable creatorsData={data?.creatorsData} />
            </div>
          )}
        </>
      )}
    </>
  );
}

const Loader = () => (
  <div className="flex justify-center pt-16">
    <Spinner size="h-6 w-6" />
  </div>
);

const StatsRow = ({
  name,
  signedUp,
  activated,
}: {
  name: string;
  signedUp: number;
  activated: number;
}) => (
  <tr className="divide-x">
    <td className="text-black-500 w-40 px-4 py-2 text-sm font-bold">{name}</td>
    <td className="px-4 py-2">{signedUp ? numberWithCommas(signedUp) : 0}</td>
    <td className="px-4 py-2">{activated ? numberWithCommas(activated) : 0}</td>
  </tr>
);

const CreatorsTable = ({
  creatorsData,
}: {
  creatorsData: BrandCreatorsData;
}) => (
  <div className="mb-8 flex flex-col">
    <div className="">
      <Subheading>Creator Stats</Subheading>
    </div>
    <div className="mt-2 w-fit overflow-hidden rounded-lg border">
      <table className=" table-auto  ">
        <thead className="bg-gray-200 ">
          <tr className="divide-x divide-gray-300">
            {creatorsTableHeaders.map((item) => (
              <th
                key={item.id}
                className={`
                    px-4 py-2 text-left text-sm font-semibold text-gray-900 `}
              >
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y ">
          <StatsRow
            name="Creators:"
            signedUp={creatorsData?.creators}
            activated={creatorsData?.activatedCreators}
          />
          <StatsRow
            name="Total Followership:"
            signedUp={creatorsData?.followership}
            activated={creatorsData?.activatedFollowership}
          />
        </tbody>
      </table>
    </div>
  </div>
);

const creatorsTableHeaders = [
  {
    id: 0,
    name: "",
  },
  {
    id: 1,
    name: "Signed Up",
  },
  {
    id: 2,
    name: "Activated",
  },
];

const referrallCodesTableHeaders = [
  {
    id: 0,
    name: "Unique Link",
  },
  {
    id: 1,
    name: "Signed Up",
  },
  {
    id: 2,
    name: "Activated",
  },
];

const CodeBreakoutTable = ({
  creatorsData,
}: {
  creatorsData: BrandCreatorsData;
}) => (
  <div className="mb-8 mt-4  flex flex-col">
    <div className="">
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Creators by marketing channel
      </h3>
    </div>
    {creatorsData?.referralCodeBreakdown.length > 0 ? (
      <div className="mt-2 w-fit overflow-hidden rounded-lg border">
        <table className=" table-auto  ">
          <thead className="bg-gray-200 ">
            <tr className="divide-x divide-gray-300">
              {referrallCodesTableHeaders.map((item) => (
                <th
                  key={item.id}
                  className={`
                    px-4 py-2 text-left text-sm font-semibold text-gray-900 `}
                >
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y ">
            {creatorsData?.referralCodeBreakdown?.map((item) => (
              <CodesRow key={item.referralCode} item={item} />
            ))}
          </tbody>
        </table>
      </div>
    ) : null}
  </div>
);

const CodesRow = ({ item }: { item: BrandReferralCodeCount }) => {
  return (
    <tr className="divide-x ">
      <td className="w-64 max-w-xs truncate px-4 py-2 text-sm">
        {item?.referralCode}
      </td>
      <td className="px-4 py-2 text-sm">{prettyDecimal(item?.count)}</td>
      <td className="px-4 py-2 text-sm">
        {prettyDecimal(item?.activatedCount)}
      </td>
    </tr>
  );
};
