import { Challenge } from "@kalecard/common";
import { Badge } from "../catalyst/badge";

export const renderChallengeState = (state: string) => {
  switch (state) {
    case "ACTIVE":
      return (
        <div className="inline-flex rounded-full bg-kale-mint-400 px-3 py-1.5 text-xs leading-5 text-black">
          Active
        </div>
      );
    case "INACTIVE":
      return (
        <div className="inline-flex rounded-full bg-zinc-100 px-3 py-1.5 text-xs leading-5 text-black">
          Inactive
        </div>
      );
    case "PENDING_REVIEW":
      return (
        <div className="inline-flex rounded-full bg-green-700 px-3 py-1.5 text-xs leading-5 text-white">
          In Review By Kale
        </div>
      );
    case "REJECTED":
      return (
        <div className="inline-flex rounded-full bg-green-900 px-3 py-1.5 text-xs leading-5 text-white">
          Rejected By Kale
        </div>
      );
    default:
      return null;
  }
};

export const renderActivationStatus = (challenge: Challenge) => {
  const hasActivationRequest = challenge.feedback?.some(
    feedbackItem => feedbackItem.status !== 'ADDRESSED' && feedbackItem.feedback === "Activation Request"
  );

  const hasDeactivationRequest = challenge.feedback?.some(
    feedbackItem => feedbackItem.status !== 'ADDRESSED' && feedbackItem.feedback === "Deactivation Request"
  );

  if (hasActivationRequest) {
    return (
      <Badge color="emerald" className="mt-2">Activation Requested</Badge>
    );
  }

  if (hasDeactivationRequest) {
    return (
      <Badge color="red" className="mt-2">Deactivation Requested</Badge>
    );
  }

  return null;
}

export function formatMetric(metric: number): string {
  return Intl.NumberFormat("en", { notation: "compact" }).format(metric);
}
